import React, { useEffect, useState } from 'react'
import './css/Register.css'
import { Link } from 'react-router-dom'
import { useGoogleLogin } from '@react-oauth/google';
import { googleLogout } from '@react-oauth/google';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const Register = () => {
    const api_url = process.env.REACT_APP_API_URL;
    const api_key = process.env.REACT_APP_API_KEY;
    const client_id = process.env.REACT_APP_API_CLIENT_ID;

    window.scrollTo(0, 0);
    useEffect(() => {
        document.title = 'Register - Aizen Styles';
        document.querySelector('meta[name="description"]').setAttribute("content", "Create an account at Aizen Styles to access your orders, discover products, and more.");
    }, []);

    const [message, setMessage] = useState('');
    const [emailMessage, setEmailMessage] = useState('');
    const [confirmPassMessage, setConfirmPassMessage] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [formData,setFormData] = useState({first_name:"",last_name:"",email:"",phone:"",password:"", cartData: localStorage.getItem('cartItems') ? localStorage.getItem('cartItems') : []});

    const changeHandler = (e) => {
        const { name, value } = e.target;
        
        if (name === 'phone') {
            // This will only allow numbers to be entered in the phone number field
            const regex = /^[0-9\b]+$/;
            if (value === '' || regex.test(value)) {
                setFormData({ ...formData, [name]: value });
            }
        } else {
            setFormData({ ...formData, [name]: value });
        }
    }

    const handleConfirmPassword = (e) => {
        setConfirmPassword(e.target.value);
    }

    const formValidation = () => {
        if (formData.first_name === '' || formData.last_name === '' || formData.email === '' || formData.phone === ''|| formData.password === '', confirmPassword === '') {
            setMessage("Please fill in all fields.");
            const message = document.querySelector('.message');
            message.classList.remove('hide');
            return false;
        }
        else {
            const message = document.querySelector('.message');
            message.classList.add('hide');
        }

        // email validation
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        if (!emailRegex.test(formData.email)) {
            setEmailMessage("Please enter a valid email address.");
            const emailError = document.querySelector('.emailMessage');
            emailError.classList.remove('hide');
            return false;
        }
        else {
            const emailError = document.querySelector('.emailMessage');
            emailError.classList.add('hide');
        }

        if (formData.password !== confirmPassword) {
            setConfirmPassMessage("Passwords do not match.");
            const confirmPassError = document.querySelector('.confirmPassMessage');
            confirmPassError.classList.remove('hide');
            return false;
        }


        return true;
    }

    const handleRegister = async () => {
        if (!formValidation()) {
            return;
        }
        try {
            let dataObj;
            await fetch(`${api_url}/register`, {
                method: 'POST',
                headers: {
                    Accept:'application/form-data',
                    'Content-Type':'application/json',
                    'x-api-key': api_key,
                    'x-client-id': client_id,
                },
                body: JSON.stringify(formData),
            })
            .then(response => response.json())
            .then(data => {dataObj = data});
            
            if (dataObj.success) {
                localStorage.setItem('auth-token',dataObj.token);
                window.location.replace("/");
                }
            else{
                setMessage(dataObj.errors);
                const message = document.querySelector('.message');
                message.classList.remove('hide');
            }

        } catch (error) {
            setMessage("Something went wrong. Please try again later.");
            const message = document.querySelector('.message');
            message.classList.remove('hide');
        }
    }

    const handleGoogleLoginSuccess = async (authRes) => {
        try {
            if (authRes['code']) {
                fetch(`${api_url}/auth/google`, {
                    method: 'POST',
                    headers: {
                        Accept:'application/form-data',
                        'Content-Type':'application/json',
                        'x-api-key': api_key,
                        'x-client-id': client_id,
                    },
                    body: JSON.stringify({code: authRes['code']}),
                })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        localStorage.setItem('auth-token',data.token);
                        window.location.replace("/");
                    }
                    else{
                        setMessage(data.errors);
                        const message = document.querySelector('.message');
                        message.classList.remove('hide');
                    }
                });   
            }
        } catch (error) {
            console.error("Error fetching Google user info:", error);
        }
    };

    const handleGoogleLoginError = () => {
        console.error("Google login failed");
    };

    const handleGoogleLogin = useGoogleLogin({
        onSuccess: handleGoogleLoginSuccess,
        onFailure: handleGoogleLoginError,
        isSignedIn: true,
        accessType: 'offline',
        prompt: 'consent',
        scope: 'openid email profile', // Ensure the scope includes 'openid'
        flow: 'auth-code',
    });

    const handleRickRoll = () => {
        window.open("https://www.youtube.com/shorts/SXHMnicI6Pg", "_blank");
    };

  return (
    <div className='section-register'>
        <div className="register-form">
            <h1 className="section-title">Create Your Account</h1>
            <div className="message hide">
                <p>{message}</p>
            </div>
            <div className="form">
                <div className="form-group name">
                    <div className="firstname">
                        <label htmlFor="first_name">First Name</label>
                        <input type="text" name="first_name" id="first_name" value={formData.first_name} onChange={changeHandler} required/>
                    </div>
                    <div className="lastname">
                        <label htmlFor="last_name">Last Name</label>
                        <input type="text" name="last_name" id="last_name" value={formData.last_name} onChange={changeHandler} required/>
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input type="email" name="email" id="email" value={formData.email} onChange={changeHandler} required/>
                    <div className="emailMessage input-message hide">
                        <p>{emailMessage}</p>
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="phone">Phone Number</label>
                    <input type="text" name="phone" id="phone" value={formData.phone} onChange={changeHandler} required/>
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input type="password" name="password" id="password" value={formData.password} onChange={changeHandler} required/>
                </div>
                <div className="form-group">
                    <label htmlFor="confirm-password">Confirm Password</label>
                    <input type="password" name="confirm-password" id="confirm-password" value={confirmPassword} onChange={handleConfirmPassword} required/>
                    <div className="confirmPassMessage input-message hide">
                        <p>{confirmPassMessage}</p>
                    </div>
                </div>
                <div className="form-group">
                    <button type="submit" className="btn" onClick={handleRegister}>Sign Up
                    <FontAwesomeIcon icon={faArrowRight} /></button>
                </div>
            </div>
            <div className="go-to-login">
                <p>Already have an account? <Link to="/login">Login</Link></p>
            </div>
            <div className="line"></div>
            <div className="google-login-wrapper">
                <button className="google-login-button" onClick={() => handleGoogleLogin()}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="h-4 w-4 text-neutral-800 dark:text-neutral-300"><path d="M20.945 11a9 9 0 1 1 -3.284 -5.997l-2.655 2.392a5.5 5.5 0 1 0 2.119 6.605h-4.125v-3h7.945z"></path></svg>
                Google
                </button>
            </div>
            {/* <div className="google-login-wrapper">
                <button className="google-login-button" onClick={handleRickRoll}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="h-4 w-4 text-neutral-800 dark:text-neutral-300"><path d="M8.5 6a6.5 6.5 0 1 0 0 13a6.5 6.5 0 0 0 0 -13z"></path><path d="M8.5 15a2.5 2.5 0 1 1 0 -5a2.5 2.5 0 0 1 0 5z"></path><path d="M14 16c2.5 0 6.42 -1.467 7 -4h-6c3 -1 6.44 -3.533 7 -6h-4c-3.03 0 -3.764 -.196 -5 1.5"></path></svg>
                OnlyFans
                </button>
            </div> */}
        </div>
    </div>
  )
}

export default Register
