import React, {useEffect, useState} from 'react';
import './SearchOverlay.css'; // Create a CSS file for overlay styles

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as fas from '@fortawesome/free-solid-svg-icons';

const SearchOverlay = ({ isVisible, onClose }) => {

    const api_url = process.env.REACT_APP_API_URL;
    const api_key = process.env.REACT_APP_API_KEY;
    const client_id = process.env.REACT_APP_API_CLIENT_ID;

    const [search, setSearch] = useState('');
    const [recommendations, setRecommendations] = useState([]);

    useEffect(() => {
        fetch(`${api_url}/featured`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': api_key,
                'x-client-id': client_id,
            }
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                setRecommendations(data.products.slice(-3));
            }
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }, []);

    useEffect(() => {
        if (isVisible){
            document.getElementById('search').focus();
        }
    }, [isVisible]);

    const handleSearchQuery = () => {
        const query = encodeURIComponent(search.toLowerCase());
        setSearch('');
        window.location.href = `/search?query=${query}`;
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSearchQuery();
        }
    }
    const changeHanler = (e) => {
        setSearch(document.getElementById('search').value);
    }

    const handleClick = (e) => {
        if (e.target.classList.contains('search-overlay') && !e.target.closest('.search-box')) {
            onClose();
        }
    }

    const handleRecommendationClick = (pid) => {
        window.location.href = `/product/${pid}`;
    }

    if (!isVisible) return null;
    
    return (
    <div className="search-overlay" onClick={handleClick}>
      <div className="overlay-content">
        <div className="search-box">
            <div className="search-head">
                <div className="search-bar">
                    <input type="text" name="search" id="search" value={search} onChange={changeHanler} onKeyDown={handleKeyDown} placeholder='Search' />
                </div>
                <div className="search-btn" onClick={handleSearchQuery}>
                    <FontAwesomeIcon icon={fas.faSearch} />
                </div>
            </div>
            <div className="suggestion-container">
                <div className="suggestion"></div>
                <div className="suggestion"></div>
            </div>
            {recommendations && recommendations.length > 0 && (
            <div className="recommendation-container">
                <div className="recommendation">
                    <div className="product-image">
                        <img src={recommendations[0].images[0]} alt="product" onClick={() => handleRecommendationClick(recommendations[0].pid)} />
                    </div>
                    <div className="product-title">
                        <p>Hoodie#1</p>
                    </div>
                </div>
                <div className="recommendation">
                    <div className="product-image">
                        <img src={recommendations[1].images[0]} alt="product" onClick={() => handleRecommendationClick(recommendations[1].pid)} />
                    </div>
                    <div className="product-title">
                        <p>Hoodie#2</p>
                    </div>
                </div>
                <div className="recommendation">
                    <div className="product-image">
                        <img src={recommendations[2].images[0]} alt="product" onClick={() => handleRecommendationClick(recommendations[2].pid)} />
                    </div>
                    <div className="product-title">
                        <p>Hoodie#3</p>
                    </div>
                </div>
            </div>
            )}
        </div>
      </div>
    </div>
    );
};

export default SearchOverlay;