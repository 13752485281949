import React, { useContext, useEffect, useState } from 'react';
import './ProductDisplay.css';
import { ShopContext } from '../../Context/ShopContext';
import RelatedProducts from '../RelatedProducts/RelatedProducts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as fas from '@fortawesome/free-solid-svg-icons';

import wishlist from '../Assets/icons/wishlist-black.png';

// import sizecharts
// oversized tees
import shokaiRegularHoodieSizechart from '../Assets/sizecharts/shokai-regular-hoodie-sizechart.png';
import qikinkOversizedTeeSizechart from '../Assets/sizecharts/qikink-oversized-tee-sizechart.png';
import inkingoOversizedTeeSizechart from '../Assets/sizecharts/inkingo-oversized-tee-sizechart.png';
// regular tees
import qikinkRegularTeeSizechart from '../Assets/sizecharts/qikink-regular-tee-sizechart.png';
// import inkingoRegularTeeSizechart from '../Assets/sizecharts/inkingo-regular-tee-sizechart.png';
// crop tops
import qikinkCropTopSizechart from '../Assets/sizecharts/qikink-crop-top-sizechart.png';

const ProductDisplay = (props) => {
  const pid = props.pid;

  const api_url = process.env.REACT_APP_API_URL;
  const api_key = process.env.REACT_APP_API_KEY;
  const client_id = process.env.REACT_APP_API_CLIENT_ID;

  const [product, setProduct] = useState([]);
  const [displayImage, setDisplayImage] = useState(null);
  const [displayPrice, setDisplayPrice] = useState(null);
  const [displayMRP, setDisplayMRP] = useState(null);
  
  const {addToCart} = useContext(ShopContext);
  
  // State to track active size button
  const [selectedColor, setSelectedColor] = useState(null);
  const [activeSize, setActiveSize] = useState(null);

  const [touchStartX, setTouchStartX] = useState(0);
  const [touchEndX, setTouchEndX] = useState(0);

  // let featureList = ['7 day replacement', 'Cash on delivery available', 'Free delivery on prepaid orders', 'Premium Quality', '100% Cotton'];

  useEffect(() => {
    window.scrollTo(0, 0);
    const getProduct = async () => {
      await fetch(`${api_url}/getproductbypid`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': api_key,
          'x-client-id': client_id,
        },
        body: JSON.stringify({pid: pid})
      })
      .then((res) => res.json())
      .then((data) => {
        if (data.success){
          setProduct(data.product);
        }
      });
    };
    getProduct();
  }, []);
  
  useEffect(() => {
    setSelectedColor(product.colors && product.colors[0]);
    const thumbnails = document.querySelectorAll('.thumbnail');
    if (thumbnails.length > 0) {
      thumbnails[0].classList.add('active');
    }
  }, [product]);

  useEffect(() => {
    if (selectedColor!=null && selectedColor.images!=null && selectedColor.images.length>0) {
      const preloadImages = async (urls) => {
        const promises = urls.map((url) => {
          return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = url;
            img.onload = () => resolve(img);
            img.onerror = () => reject(new Error(`Failed to load image: ${url}`));
          });
        });
        return Promise.all(promises);
      };

      preloadImages(selectedColor.images).then((images) => {
        const urls = images.map((image) => image.currentSrc);
        selectedColor.images = urls;
      });

      setDisplayImage(selectedColor.images[0]);
      if (activeSize === null) {
        setDisplayPrice(selectedColor.sizes[0].sellingPrice);
        setDisplayMRP(selectedColor.sizes[0].mrp);
        setActiveSize(selectedColor.sizes[0].size);
      }
      else {
        const selected = selectedColor.sizes.find(s => s.size === activeSize);
        setDisplayPrice(selected.sellingPrice);
        setDisplayMRP(selected.mrp);
      }
    }
  }, [selectedColor]);

  const handleNextImage = () => {
    const imagesCount = selectedColor.images.length;
    setDisplayImage(selectedColor.images[(selectedColor.images.indexOf(displayImage) + 1) % imagesCount]);
  };

  const handlePrevImage = () => {
    const imagesCount = selectedColor.images.length;
    setDisplayImage(selectedColor.images[(selectedColor.images.indexOf(displayImage) - 1 + imagesCount) % imagesCount]);
  };

  const handleSwipeRight = () => {
    handleNextImage();
  };

  const handleSwipeLeft = () => {
    handlePrevImage();
  };

  const handleTouchStart = (e) => {
    setTouchStartX(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEndX(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchStartX - touchEndX > 50) {
        handleSwipeRight();
    }
    if (touchStartX - touchEndX < -50) {
        handleSwipeLeft();
    }
  };

  const handleAddToCart = (currentSp, currentMrp) => {
    const overlay = document.querySelector('.overlay');
    const cartPopup = document.querySelector('.cart-popup');
    overlay.classList.remove('hide');
    cartPopup.classList.remove('hide');
    
    addToCart(pid, selectedColor, activeSize);
  };

  const handleOpenSizeChartPopup = () => {
    const overlay = document.querySelector('.overlay');
    const sizechartPopup = document.querySelector('.sizechart-popup');
    overlay.classList.remove('hide');
    sizechartPopup.classList.remove('hide');
  };

  const handleCloseSizeChartPopup = () => {
    const overlay = document.querySelector('.overlay');
    const sizechartPopup = document.querySelector('.sizechart-popup');
    overlay.classList.add('hide');
    sizechartPopup.classList.add('hide');
  };

  const handleCLoseCartPopup = () => {
    const overlay = document.querySelector('.overlay');
    const cartPopup = document.querySelector('.cart-popup');
    overlay.classList.add('hide');
    cartPopup.classList.add('hide');
  };

  const handleViewCart = () => {
    window.location.href = '/cart';
  };

  const handleImageDisplay = (e) => {
    setDisplayImage(e.target.src);
    const thumbnails = document.querySelectorAll('.thumbnail');
    thumbnails.forEach(thumbnail => {
      thumbnail.classList.remove('active');
    });
    e.target.parentElement.classList.add('active');
  };

  const handleSelectSize = (size) => {
    setActiveSize(size);
    const selected = selectedColor.sizes.find(s => s.size === size);
    setDisplayPrice(selected.sellingPrice);
    setDisplayMRP(selected.mrp);
  };

  const handleSelectColor = (color) => () => {
    setSelectedColor(color);
  }
  return (
    <div className='product-display'>
      <div className="product-main">
        <div className="product-gallery">
          <div className="image-arrow-left image-arrow" onClick={handlePrevImage}>
            <FontAwesomeIcon icon={fas.faChevronLeft} />
          </div>
          {/* set left and right swipe functions */}
          <div className="product-image" onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}>
            <img src={displayImage} alt="product"/>
          </div>
          <div className="gallery-dots">
            {selectedColor && selectedColor.images.length > 1 && selectedColor.images.map((image, i) => (
              <div className={`dot ${displayImage === image ? 'active' : ''}`} key={i} onClick={() => setDisplayImage(image)}></div>
            ))}
          </div>
          <div className="image-arrow-right image-arrow" onClick={handleNextImage}>
            <FontAwesomeIcon icon={fas.faChevronRight} />
          </div>
        </div>
        <div className="product-details">
          <div className="product-title">{product.name}</div>
          {selectedColor && selectedColor.sizes.map((size, i) => {
            if (size.size === activeSize && size.quantity != 0 ) {
            return (<>
          <div className="product-price">
            <span className="sp"><span className='rs-symbol'>&#8377;</span> {displayPrice} </span>
            <span className="mrp line-cut">MRP: <span><span className='rs-symbol'>&#8377;</span> {displayMRP} </span></span>
            <span className="price-note" hidden>Incl. of all taxes</span>
          </div>
          <div className="discount-label">
            <span className="discount">{Math.round(((displayMRP - displayPrice) / displayMRP) * 100)}% off</span>
          </div>
          </>)
          }
          else if (size.size === activeSize && size.quantity == 0) {
            return (
            <div className="product-price">
              <span className="out-of-stock">Out of Stock</span>
            </div>
            )
          }})}
          {product.colors && product.colors.length > 1 &&
          <div className="color-menu">
            <label className="color">Color</label>
            <div className="line"></div>
            <div className="menu-options">
              {product.colors && product.colors.map((color, i) => (
                <div className={`option ${selectedColor && color.hex === selectedColor.hex ? 'active' : ''}`}>
                  <div key={i} className={`color-preview`} id={`cololorBtn-${i}`} style={{backgroundColor: color.hex}} onClick={handleSelectColor(color)}>
                  </div>
                </div>
              ))}
            </div>
          </div>
          }
          <div className='size-menu'>
            <div className="label">
              <label className='label'>Size</label>
              <div className='size-guide-btn' onClick={handleOpenSizeChartPopup}>Size Guide</div>
            </div>
            <div className="line"></div>
            <div className='menu-options'>
              {selectedColor && selectedColor.sizes.map((size, i) => (
                <div className='option' key={i}>
                  <button className={activeSize === size.size ? 'active' : ''}
                onClick={() => handleSelectSize(size.size)}>{size.size}</button>
                </div>
              ))}
            </div>
          </div>

          <div className="product-cta">
            {selectedColor && selectedColor.sizes.map((size, i) => {
              if (size.size === activeSize && size.quantity != 0) {
                return (
                  <div className="add-to-cart">
                      <button onClick={handleAddToCart}>Add to Cart</button>
                    <div className="wishlist" hidden>
                      <img src={wishlist} alt="" hidden/>
                    </div>
                  </div>
                )
              }
              else if (size.size === activeSize && size.quantity == 0) {
                return (
                  <div className="add-to-cart">
                      <button className="disabled" onClick={handleAddToCart} disabled>Add to Cart</button>
                    <div className="wishlist" hidden>
                      <img src={wishlist} alt="" hidden/>
                    </div>
                  </div>
                )
              }
            })}
          </div>          
        </div>
      </div>

      <div className="core-features">
        <div className="core-features-head">
          <h2 className="title">Core Features</h2>
        </div>
        <div className="line"></div>
        <div className="features">
          <ul>
            {/* add color li */}
            <li>Color: {selectedColor && selectedColor.name}</li>
            {product && product.features && product.features.map((feature, i) => (
              <li key={i}>{feature}</li>
            ))}
          </ul>
        </div>
      </div>

      <div className="journey">
        <div className="journey-head">
          <h2 className='title'>Product Journey</h2>
        </div>
        <div className="line"></div>
        <div className="process">
          {product && product.journey && product.journey.map((step, i) => (
          <div className="journey-block">
            <div className="block-number">{step.title}</div>
            <div className="block-image">
              <img src={step.image} alt="step-1" />
            </div>
            <div className="block-text">
              <p>
                {step.description}
              </p>
            </div>
          </div>
          ))}
        </div>
      </div>

      <div className="information">
        <div className="line"></div>
        {/* <h2 className='title'>Information</h2> */}
        <div className="shipping info-block">
          <div className="info-title">
            <span className="label">Shipping & Delivery</span>
          </div>
          <div className="content">
            <p>We currently offer free delivery on prepaid orders and Rs. 40 is charged on COD (Cash on Delivery) orders. Times may vary based on your location. Please refer to our <a href="/policy/shipping-policy">Shipping Policy</a> for more information.</p>
          </div>
        </div>
        <div className="returns info-block">
          <div className="info-title">
            <span className="label">Returns & Exchanges</span>
          </div>
          <div className="content">
            <p>Please refer to our <a href="/policy/return-policy">Return Policy</a> for information on returns and exchanges.</p>
          </div>
        </div>
        <div className="payment info-block">
          <div className="info-title">
            <span className="label">Payment</span>
          </div>
          <div className="content">
            <p>We accept Cash on Delivery, UPI, Credit card, Debit Card, Net Banking. Payments are processed securely, and your payment information is not stored.</p>
          </div>
        </div>
      </div>
      
      {product && <RelatedProducts title="Related Products" pid={pid} categories={product.categories} collections={product.collections} tags={product.tags}/>}

      <div className="overlay hide">
        <div className="cart-popup hide">
          <div className="cart-popup-content">
            <p>{`Your item has been added to the cart :)`}</p>
          </div>
          <div className="cart-popup-cta">
            <div className="continue btn" onClick={handleCLoseCartPopup}>Okay</div>
            <div className="view-cart btn" onClick={handleViewCart}>View Cart</div>
          </div>
        </div>
        <div className="sizechart-popup hide">
          <div className="sizechart-popup-content">
          {/* for oversized tees sizechart */}
          {selectedColor && product && selectedColor.manufacturer === 'shokai' && product.type === 'hoodie' && product.style === 'regular' &&
            <img src={shokaiRegularHoodieSizechart} alt="sizechart" />
          }
          {selectedColor && product && selectedColor.manufacturer === 'qikink' && product.type === 't-shirt' && product.style === 'oversized' &&
            <img src={qikinkOversizedTeeSizechart} alt="sizechart" />
          }
          {selectedColor && product && selectedColor.manufacturer === 'inkingo' && product.type === 't-shirt' && product.style === 'oversized' &&
            <img src={inkingoOversizedTeeSizechart} alt="sizechart" />
          }

          {/* for regular tees sizechart */}
          {selectedColor && product && selectedColor.manufacturer === 'qikink' && product.type === 't-shirt' && product.style === 'regular' &&
            <img src={qikinkRegularTeeSizechart} alt="sizechart" />
          }
          {/* {selectedColor && product && selectedColor.manufacturer === 'inkingo' && product.type === 't-shirt' && product.style === 'regular' &&
            <img src={inkingoRegularTeeSizechart} alt="sizechart" />
          } */}

          {/* for crop tops */}
          {selectedColor && product && selectedColor.manufacturer === 'qikink' && product.type === 'crop-top' &&
            <img src={qikinkCropTopSizechart} alt="sizechart" />
          }
          </div>
          <div className="close btn" onClick={handleCloseSizeChartPopup}>
            <FontAwesomeIcon icon={fas.faTimes} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductDisplay