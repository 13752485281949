import React, { useEffect, useState } from 'react';
import './css/FreeformShopLayout.css';
import FreeformGridItem from '../Components/FreeformGridItem/FreeformGridItem';

const FreeformShopLayout = (props) => {
    const api_url = process.env.REACT_APP_API_URL;
    const api_key = process.env.REACT_APP_API_KEY;
    const client_id = process.env.REACT_APP_API_CLIENT_ID;

    const [allproducts, setAllProducts] = useState([]);

    useEffect(() => {
        if (props.category === 'men') {
            document.title = "Shop Latest Men Genz Fashion - Aizenstyles.in";
            document.querySelector('meta[name="description"]').setAttribute("content", "Shop Latest Men Fashion At Aizen Styles. Discover Latest Trends");
        } else if (props.category === "women") {
            document.title = "Shop Latest Women Genz Fashion - Aizenstyles.in";
            document.querySelector('meta[name="description"]').setAttribute("content", "Shop The Latest Women Fashion At Aizen Styles. Discover Latest Trends");
        } else if (props.category === "unisex") {
            document.title = "Shop Latest Unisex Genz Fashion - Aizenstyles.in";
            document.querySelector('meta[name="description"]').setAttribute("content", "Shop Latest Unisex Fashion At Aizen Styles. Discover Latest Trends");
        }
        else if (props.category === "anime") {
            document.title = "Shop Latest Anime Genz Fashion - Aizenstyles.in";
            document.querySelector('meta[name="description"]').setAttribute("content", "Shop Latest Anime Fashion At Aizen Styles. Discover Latest Trends");
        }
    }, [props.category]);

    useEffect(() => {
        window.scrollTo(0, 0);

        if (props.category.toLowerCase() != "all") {
            fetch(`${api_url}/getcategoryproducts`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': api_key,
                    'x-client-id': client_id,
                },
                body: JSON.stringify({category: props.category.toLowerCase()}),
            })
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    setAllProducts(data.products);
                }
            })
        }
        else {
            fetch(`${api_url}/allproducts`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': api_key,
                    'x-client-id': client_id,
                },
            })
            .then((res) => res.json())
            .then((data) => {
                data.forEach((item) => {
                    if (item.active_status === true){
                        setAllProducts((prev) => [...prev, item]);                        
                    }
                });
            })
        }
    }, [props.category]);

  return (
    <div className='freeform-shop'>
        <div className="shop-head">
            <h1 className='shop-title'>{props.title}</h1>
        </div>
        <div className="products">
            <div className="items">
            {allproducts && allproducts.map((item,i)=>{
                return <FreeformGridItem key={i} pid={item.pid} name={item.name} description={item.description} images={item.images} colors={item.colors}/>
            })}
            </div>
        </div>
    </div>
  )
}

export default FreeformShopLayout

