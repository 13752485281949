import React, { useEffect, useState } from 'react'
import './FeaturedProducts.css'
import AOS from 'aos';
import 'aos/dist/aos.css';

const FeaturedProducts = () => {
    
    const api_url = process.env.REACT_APP_API_URL;
    const api_key = process.env.REACT_APP_API_KEY;
    const client_id = process.env.REACT_APP_API_CLIENT_ID;

    const [featuredProducts, setFeaturedProducts] = useState([]);

    useEffect(() => {
        AOS.init();
    }, []);

    useEffect(() => {
        fetch(`${api_url}/featured`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': api_key,
                'x-client-id': client_id,
            }
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                setFeaturedProducts(data.products.slice(-6));
            }
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }, []);

    const handleProductClick = (pid) => {
        window.location.href = `/product/${pid}`;
    }

  return (
    // reverse the aos animation
    <div className='featured-products' data-aos="fade-up" data-aos-duration="2500" data-aos-once="true">
        <div className="section-head">
            <h1 className='section-title'>Featured Products</h1>
            {/* <h3 className='section-description'>Discover our featured products that are loved by our customers.</h3> */}
        </div>
        {featuredProducts && featuredProducts.length > 0 && (
        <div className="products">
            <div className="frame frame1">
                <div className="item item1">
                    <img src={featuredProducts[0].images[0]} alt="product1" onClick={() => handleProductClick(featuredProducts[0].pid)} />  
                </div>
                <div className="item item2">
                    <img src={featuredProducts[1].images[0]} alt="product2" onClick={() => handleProductClick(featuredProducts[1].pid)} />
                </div>
            </div>
            <div className="frame frame2">
                <div className="item item3">
                    <img src={featuredProducts[2].images[0]} alt="product3" onClick={() => handleProductClick(featuredProducts[2].pid)} />
                </div>
                <div className="item item4">
                    <img src={featuredProducts[3].images[0]} alt="product4" onClick={() => handleProductClick(featuredProducts[3].pid)} />
                </div>
            </div>
            <div className="frame frame3">
                <div className="item item5">
                    <img src={featuredProducts[4].images[0]} alt="product5" onClick={() => handleProductClick(featuredProducts[4].pid)} />
                </div>
                <div className="item item6">
                    <img src={featuredProducts[5].images[0]} alt="product6" onClick={() => handleProductClick(featuredProducts[5].pid)} />
                </div>
            </div>
        </div>
        )}
        {featuredProducts && featuredProducts.length > 0 && (
        <div className="mobile-products hide">
            <div className="frame frame1">
                <div className="item item1">
                    <img src={featuredProducts[0].images[0]} alt="product1" onClick={() => handleProductClick(featuredProducts[0].pid)} />
                </div>
                <div className="item item2">
                    <img src={featuredProducts[1].images[0]} alt="product2" onClick={() => handleProductClick(featuredProducts[1].pid)} />
                </div>
                <div className="item item3">
                    <img src={featuredProducts[2].images[0]} alt="product3" onClick={() => handleProductClick(featuredProducts[2].pid)} />
                </div>
            </div>
            <div className="frame frame2">
                <div className="item item4">
                    <img src={featuredProducts[3].images[0]} alt="product4" onClick={() => handleProductClick(featuredProducts[3].pid)} />
                </div>
                <div className="item item5">
                    <img src={featuredProducts[4].images[0]} alt="product5" onClick={() => handleProductClick(featuredProducts[4].pid)} />
                </div>
                <div className="item item6">
                    <img src={featuredProducts[5].images[0]} alt="product6" onClick={() => handleProductClick(featuredProducts[5].pid)} />
                </div>
            </div>
        </div>
        )}
    </div>
  )
}

export default FeaturedProducts














// import React from 'react'
// import './FeaturedProducts.css'

// import product1 from '../Assets/Mockups/transparent/1.png';
// import product2 from '../Assets/Mockups/transparent/2.png';
// import product3 from '../Assets/Mockups/transparent/3.png';
// import product4 from '../Assets/Mockups/transparent/4.png';
// import product5 from '../Assets/Mockups/transparent/5.png';
// import product6 from '../Assets/Mockups/transparent/6.png';

// const FeaturedProducts = () => {
//   return (
//     <div className='featured-products'>
//         <div className="section-head">
//             <h1 className='section-title'>Featured Products</h1>
//             {/* <h3 className='section-description'>Discover our featured products that are loved by our customers.</h3> */}
//         </div>
//         <div className="products">
//             <div className="frame-left frame main-frame">
//                 <div className="item item1" style={{ backgroundImage: `url(${product1})` }}>
//                 </div>
//                 <div className="item item2" style={{ backgroundImage: `url(${product3})` }}>
//                 </div>
//             </div>
//             <div className="frame-right frame main-frame">
//                 <div className="sub-frame-top frame sub-frame">
//                     <div className="item item3" style={{ backgroundImage: `url(${product2})` }}>
//                     </div>
//                 </div>
//                 <div className="sub-frame-bottom frame sub-frame">
//                     <div className="sub-frame-bottom-left frame sub-frame">
//                         <div className="item item4" style={{ backgroundImage: `url(${product4})` }}>
//                         </div>
//                     </div>
//                     <div className="sub-frame-bottom-right frame sub-frame">
//                         <div className="item item5" style={{ backgroundImage: `url(${product5})` }}>
//                         </div>
//                         <div className="item item6" style={{ backgroundImage: `url(${product6})` }}>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     </div>
//   )
// }

// export default FeaturedProducts
