import React, { useEffect, useState } from 'react'
import './css/Login.css'
import { Link } from 'react-router-dom'
import { useGoogleLogin } from '@react-oauth/google';
import { googleLogout } from '@react-oauth/google';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const Login = () => {
    const api_url = process.env.REACT_APP_API_URL;
    const api_key = process.env.REACT_APP_API_KEY;
    const client_id = process.env.REACT_APP_API_CLIENT_ID;

    window.scrollTo(0, 0);
    useEffect(() => {
        document.title = 'Login - Aizen Styles';
        document.querySelector('meta[name="description"]').setAttribute("content", "Login to your Aizen Styles account to access your orders, discover products, and more.");
    }, []);

    const [message, setMessage] = useState('');

    const [formData,setFormData] = useState({email:"",password:""});

    const changeHandler = (e) => {
        setFormData({...formData,[e.target.name]:e.target.value});
    }

    const handleLogin = async () => {
        let dataObj;

        try {
            await fetch(`${api_url}/login`, {
                method: 'POST',
                headers: {
                    Accept:'application/form-data',
                    'Content-Type':'application/json',
                    'x-api-key': api_key,
                    'x-client-id': client_id,
                },
                body: JSON.stringify(formData),
            })
            .then(response => response.json())
            .then(data => {dataObj = data});
            
            if (dataObj.success) {
                localStorage.setItem('auth-token',dataObj.token);
                let redirect = localStorage.getItem('redirect');
                let redirect_url = localStorage.getItem('redirect_url');
                localStorage.removeItem('redirect');
                localStorage.removeItem('redirect_url');
                if (redirect && redirect != null) {
                    window.location.replace(redirect_url);
                }
                else {
                    window.location.replace('/');
                }
              }
            else{
                setMessage(dataObj.errors);
                const message = document.querySelector('.message');
                message.classList.remove('hide');
            }

        } catch (error) {
            setMessage("Something went wrong. Please try again later.");
            const message = document.querySelector('.message');
            message.classList.remove('hide');
        }

    }
    
    const handleGoogleLoginSuccess = async (authRes) => {
        try {
            if (authRes['code']) {
                fetch(`${api_url}/auth/google`, {
                    method: 'POST',
                    headers: {
                        Accept:'application/form-data',
                        'Content-Type':'application/json',
                        'x-api-key': api_key,
                        'x-client-id': client_id,
                    },
                    body: JSON.stringify({code: authRes['code']}),
                })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        localStorage.setItem('auth-token',data.token);
                        window.location.replace("/");
                    }
                    else{
                        setMessage(data.errors);
                        const message = document.querySelector('.message');
                        message.classList.remove('hide');
                    }
                });   
            }
        } catch (error) {
            console.error("Error fetching Google user info:", error);
        }
    };

    const handleGoogleLoginError = () => {
        console.error("Google login failed");
    };

    const handleGoogleLogin = useGoogleLogin({
        onSuccess: handleGoogleLoginSuccess,
        onFailure: handleGoogleLoginError,
        isSignedIn: true,
        accessType: 'offline',
        prompt: 'consent',
        scope: 'openid email profile', // Ensure the scope includes 'openid'
        flow: 'auth-code',
    });

    const handleRickRoll = () => {
        window.open("https://www.youtube.com/shorts/SXHMnicI6Pg", "_blank");
    };

  return (
    <div className='section-login'>
        <div className="login-form">
            <h1 className="section-title">Log In</h1>
            <div className="message hide">
                <p>{message}</p>
            </div>
            <div action="" className="form">
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input type="email" name="email" id="email" onChange={changeHandler} value={formData.email}/>
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input type="password" name="password" id="password" onChange={changeHandler} value={formData.password}/>
                </div>
                <div className="form-group">
                    <button type="submit" className="btn" onClick={handleLogin}>Login <FontAwesomeIcon icon={faArrowRight} /></button>
                </div>
            </div>
            <div className="cta-action">
                <div className="forgot-password">
                    <p>Forgot Password? <Link to="/forgotpassword">Reset</Link></p>
                </div>
                <div className="go-to-register">
                    <p>Don't have an account? <Link to="/register">Register</Link></p>
                </div>
            </div>
            <div className="line"></div>
            <div className="google-login-wrapper">
                <button className="google-login-button" onClick={() => handleGoogleLogin()}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="h-4 w-4 text-neutral-800 dark:text-neutral-300"><path d="M20.945 11a9 9 0 1 1 -3.284 -5.997l-2.655 2.392a5.5 5.5 0 1 0 2.119 6.605h-4.125v-3h7.945z"></path></svg>
                Google
                </button>
            </div>
            {/* <div className="google-login-wrapper">
                <button className="google-login-button" onClick={handleRickRoll}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="h-4 w-4 text-neutral-800 dark:text-neutral-300"><path d="M8.5 6a6.5 6.5 0 1 0 0 13a6.5 6.5 0 0 0 0 -13z"></path><path d="M8.5 15a2.5 2.5 0 1 1 0 -5a2.5 2.5 0 0 1 0 5z"></path><path d="M14 16c2.5 0 6.42 -1.467 7 -4h-6c3 -1 6.44 -3.533 7 -6h-4c-3.03 0 -3.764 -.196 -5 1.5"></path></svg>
                OnlyFans
                </button>
            </div> */}
        </div>
    </div>
  )
}

export default Login
