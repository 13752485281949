import React, { useEffect, useState, useCallback } from "react";
import "./Orders.css";

import useAuth from "../../hooks/useAuth";
import { load } from "@cashfreepayments/cashfree-js";

const Orders = () => {
  const api_url = process.env.REACT_APP_API_URL;
  const api_key = process.env.REACT_APP_API_KEY;
  const client_id = process.env.REACT_APP_API_CLIENT_ID;
  const frontend_url = process.env.REACT_APP_FRONTEND_URL;
  const cashfree_mode = process.env.REACT_APP_CASHFREE_MODE;
  const cashfreeRedirectTarget = process.env.REACT_APP_CASHFREE_REDIRECT_TARGET;

  const { isAuthenticated, cid } = useAuth();
  const [orders, setOrders] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  // Fetch customer orders
  useEffect(() => {
    if (!cid) return;

    const fetchOrders = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${api_url}/getcustomerorders`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": api_key,
            "x-client-id": client_id,
          },
          body: JSON.stringify({ cid }),
        });
        const data = await response.json();
        if (data.success) {
          setOrders(data.orders.reverse());
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [cid]);

  // Fetch product details
  useEffect(() => {
    if (orders.length === 0) return;

    const fetchProducts = async () => {
      try {
        const uniquePids = [
          ...new Set(orders.flatMap((order) => order.order_items.map((item) => item.pid))),
        ];
        const productPromises = uniquePids.map((pid) =>
          fetch(`${api_url}/getproductbypid`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "x-api-key": api_key,
              "x-client-id": client_id,
            },
            body: JSON.stringify({ pid }),
          }).then((res) => res.json())
        );

        const productsData = await Promise.all(productPromises);
        const successfulProducts = productsData.filter((data) => data.success).map((data) => data.product);
        setProducts(successfulProducts);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, [orders]);

  const handleViewOrder = useCallback((oid) => {
    if (!isAuthenticated) {
      window.location.href = "/login";
      return;
    }
    window.location.href = `/order/${oid}`;
  }, [isAuthenticated]);

  const retryPayment = async (oid) => {
    try {
      const cashfree = await load({ mode: cashfree_mode });

      const response = await fetch(`${api_url}/verifyorder`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": api_key,
          "x-client-id": client_id,
        },
        body: JSON.stringify({ orderId: oid }),
      });
      const data = await response.json();

      if (data.success) {
        await cashfree.checkout({
          paymentSessionId: data.payment_session_id,
          redirectTarget: cashfreeRedirectTarget,
          returnUrl: `${frontend_url}/paymentconfirmation?orderId=${oid}&paymentMode=upi`,
        });
      }
    } catch (error) {
      console.error("Error during payment retry:", error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="section-orders">
      <div className="section-head">
        <h1 className="section-title">Order History</h1>
      </div>
      <div className="orders">
        {orders.map((order) =>
          order.order_items.map((item, index) => {
            const product = products.find((p) => p.pid === item.pid);
            const color = product?.colors.find((c) => c.name === item.color);

            return (
              <div className="order" key={index}>
                <div className="product-image">
                  {color?.images?.[0] && <img src={color.images[0]} alt="product" />}
                </div>
                <div className="product-info">
                  <div className="details">
                    <div className="details-head">
                      <div className="product-name">
                        <span>{product?.name}</span>
                      </div>
                      <div className="price">
                        <span>
                          <span className="rs-symbol">₹</span> {item.sp || "NaN"}
                        </span>
                      </div>
                    </div>
                    <div className="color">
                      <span className="label">Color:</span>
                      <span className="value">{item.color}</span>
                    </div>
                    <div className="size">
                      <span className="label">Size:</span>
                      <span className="value">{item.size}</span>
                    </div>
                    <div className="quantity">
                      <span className="label">Qty:</span>
                      <span className="value">{item.quantity}</span>
                    </div>
                  </div>
                  <div className="order-info">
                    <div className="order-date">
                      <span className="label">Order Date:</span>
                      <span className="value">
                        {new Date(order.order_date).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        })}
                      </span>
                    </div>
                    <div className="order-id">
                      <span className="label">Order ID:</span>
                      <span className="value">#{order.oid}</span>
                    </div>
                  </div>
                </div>
                <div className="order-actions">
                  <div className="btn view" onClick={() => handleViewOrder(order.oid)}>
                    View Order
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default Orders;
