import React, { useEffect, useRef, useState } from 'react'
import $, { cleanData } from 'jquery';
import './RelatedProducts.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as fas from '@fortawesome/free-solid-svg-icons';

const RelatedProducts = (props) => {
    const api_url = process.env.REACT_APP_API_URL;
    const api_key = process.env.REACT_APP_API_KEY;
    const client_id = process.env.REACT_APP_API_CLIENT_ID;

    const pid = props.pid;
    const categories = props.categories;
    const collections = props.collections;
    const tags = props.tags;

    const itemsRef = useRef(null);
    const [relatedProducts, setRelatedProducts] = useState([]);

    useEffect(() => {
        fetch(`${api_url}/relatedproducts`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': api_key,
                'x-client-id': client_id,
            },
            body: JSON.stringify({pid, categories, collections, tags})
        })
        .then(res => res.json())
        .then(data => {
            if (data.success){
                setRelatedProducts(data.relatedProducts);
            }
        })
        .catch();
    }, [pid, categories, collections, tags]);

  useEffect(() => {
    const items = $(itemsRef.current);
    const itemWidth = items.find('.item').first().outerWidth(true); // Width of each item including margin
    const delay = 3500; // Delay between each slide in milliseconds
    let positionOffset = 0; // Initial position offset

    // Function to slide the items
    const slideItems = () => {
      positionOffset -= itemWidth; // Decrement position offset
      items.animate({ left: positionOffset }, 500, 'swing', () => {
        // Move first item to the end for wrapping effect
        items.append(items.find('.item:first-child'));
        positionOffset += itemWidth; // Increment position offset after wrapping
        items.css('left', positionOffset); // Reset position offset
      });
    };

    // Automatically slide items with delay
    let slideInterval = setInterval(slideItems, delay);

    // Click event for left arrow button
    $('.related-products .left').off('click').on('click', () => {
        clearInterval(slideInterval);
      positionOffset += itemWidth; // Increment position offset
      items.animate({ left: positionOffset }, 500, 'swing', () => {
        // Move last item to the beginning for wrapping effect
        items.prepend(items.find('.item:last-child'));
        positionOffset -= itemWidth; // Decrement position offset after wrapping
        items.css('left', positionOffset); // Reset position offset
        slideInterval = setInterval(slideItems, delay);
      });
    });

    // Click event for right arrow button
    $('.related-products .right').off('click').on('click', () => {
        clearInterval(slideInterval);
      positionOffset -= itemWidth; // Decrement position offset
      items.animate({ left: positionOffset }, 500, 'swing', () => {
        // Move first item to the end for wrapping effect
        items.append(items.find('.item:first-child'));
        positionOffset += itemWidth; // Increment position offset after wrapping
        items.css('left', positionOffset); // Reset position offset
        slideInterval = setInterval(slideItems, delay);
      });
    });

    // Clean up interval on component unmount
    return () => clearInterval(slideInterval);
  }, [relatedProducts]);

  const handleViewRelatedProducts = (pid) => {
    window.open(`/product/${pid}`, '_blank');
  }
  return (
    <div className='related-products'>
        <div className="section-head">
          {props.title ? (
            <>
              <h1 className="section-title">{props.title}</h1>
              <div className="line"></div>
            </>
          ) : null}
        </div>
        <div className="slider">
            <div className="left arrow-btn">
                <FontAwesomeIcon icon={fas.faChevronLeft} />
            </div>
            <div ref={itemsRef} className="items">
                {relatedProducts && relatedProducts.map((product,i)=>{
                    return <div key={i} className="item" onClick={()=>handleViewRelatedProducts(product.pid)}>
                        <div className="product-image">
                            <img src={product.colors[0].images[0]} alt="" />
                        </div>
                        <div className="product-name">{product.name}</div>
                    </div>
                })}
            </div>
            <div className="right arrow-btn">
                <FontAwesomeIcon icon={fas.faChevronRight} />
            </div>
        </div>
    </div>
  )
}

export default RelatedProducts